import React, { useState } from 'react';
import {
  Button, Dialog, DialogContent, DialogActions, Divider,
} from '@material-ui/core';
import './ReUpload.css';
import PropTypes from 'prop-types';
import FileUploadDialog from '../components/Uploadfile/UploadFile';
import UploadIcon from './UploadIcon';


const ReUpload = ({
  fileUploadData, handleUploadState, onTabSelect, setIsOnboardEnabled,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleUploadComplete = () => {
    handleUploadState(true);
    setIsOnboardEnabled(true);
  };

  const handleNavigateToHistory = () => {
    setSuccessDialogOpen(false);
    onTabSelect(1);
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };

  return (
    <div styleName="container">
      <UploadIcon />
      <p styleName="typography">Click below to resume uploading</p>
      <Button
        color="primary"
        component="label"
        id="upload"
        onClick={() => setDialogOpen(true)}
        style={{
          label: 'uploadLabel',
        }}
        variant="contained"
      >
                RE-UPLOAD
      </Button>
      <FileUploadDialog
        data={fileUploadData}
        handleClose={handleClose}
        isOpen={dialogOpen}
        onUploadComplete={handleUploadComplete}
      />
      <Dialog fullWidth maxWidth="xs" onClose={handleSuccessDialogClose} open={successDialogOpen}>
        <DialogContent style={{ textAlign: 'center' }}>
          <img alt="upload" src="/static/img/submission.svg" style={{ width: '48px', marginBottom: '16px' }} />
          <p style={{ marginBottom: '16px' }}>Submission successful</p>
        </DialogContent>
        <Divider />
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button color="primary" onClick={handleNavigateToHistory} variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ReUpload.propTypes = {
  fileUploadData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleUploadState: PropTypes.func.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  setIsOnboardEnabled: PropTypes.func.isRequired,
};


export default ReUpload;
