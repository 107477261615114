import DateRangeIcon from '@material-ui/icons/DateRange';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import './RightPane.css';

const DateRangePicker = ({
  handleStartDate, handleEndDate, startDate1, endDate1,
}) => {
  const DATE_FORMAT = 'MM-DD-YYYY';
  const [startDate, setStartDate] = useState(startDate1);
  const [endDate, setEndDate] = useState(endDate1);
  const today = moment().toDate();

  // Update state when props change
  useEffect(() => {
    setStartDate(startDate1);
  }, [startDate1]);

  useEffect(() => {
    setEndDate(endDate1);
  }, [endDate1]);


  const handleInputStartDate = (date) => {
    setStartDate(date.toDate());
    handleStartDate(date.toDate());
  };

  const handleInputEndDate = (date) => {
    setEndDate(date.toDate());
    handleEndDate(date.toDate());
  };

  const disableStartDate = (date) => {
    const endMoment = endDate ? moment(endDate) : null;
    return moment(date).isAfter(today) || (endMoment && moment(date).isAfter(endMoment));
  };

  const disableEndDate = date => moment(date).isAfter(today) || moment(date).isBefore(startDate);

  return (
    <div styleName="date">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div>
          <KeyboardDatePicker
            format={DATE_FORMAT}
            InputLabelProps={{
              style: { fontSize: '8px', textAlign: 'left' },
            }}
            InputProps={{
              style: { fontSize: '8px', paddingRight: '0px' },
              inputProps: {
                style: { textAlign: 'left' },
              },
            }}
            inputVariant="outlined"
            KeyboardButtonProps={{
              'aria-label': 'StartDate',
              style: { padding: '0.2rem' },
            }}
            keyboardIcon={<DateRangeIcon style={{ fontSize: 'small', height: '20px', width: '20px' }} />}
            label="mm/dd/yyyy"
            onChange={event => handleInputStartDate(event)}
            shouldDisableDate={disableStartDate}
            size="small"
            style={{
              width: '90px', height: '29px', fontSize: '0.2rem',
            }}
            value={startDate}
          />
        </div>
      </MuiPickersUtilsProvider>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div>
          <KeyboardDatePicker
            format={DATE_FORMAT}
            InputLabelProps={{
              style: { fontSize: '8px', textAlign: 'left' },
            }}
            InputProps={{
              style: { fontSize: '8px', paddingRight: '0px' },
              inputProps: {
                style: { textAlign: 'left' },
              },
            }}
            inputVariant="outlined"
            KeyboardButtonProps={{
              'aria-label': 'EndDate',
              style: { padding: '0.2rem' },
            }}
            keyboardIcon={<DateRangeIcon style={{ fontSize: 'small', height: '20px', width: '20px' }} />}
            label="mm/dd/yyyy"
            onChange={handleInputEndDate}
            shouldDisableDate={disableEndDate}
            size="small"
            style={{
              width: '90px', height: '29px', fontSize: '0.2rem',
            }}
            value={endDate}
          />
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
};

DateRangePicker.defaultProps = {
  startDate1: null,
  endDate1: null,
};
DateRangePicker.propTypes = {
  endDate1: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.oneOf([null])]),
  handleEndDate: PropTypes.func.isRequired,
  handleStartDate: PropTypes.func.isRequired,
  startDate1: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.oneOf([null])]),
};

export default DateRangePicker;
