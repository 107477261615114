import React from 'react';
import PropTypes from 'prop-types';
import './RatioSection.css';
import { connect } from 'react-redux';
import { selectors as evalSelectors } from 'ducks/eval';

const RenderRatioBlock = ({
  label, preVal, postVal, tagA, tagB,
}) => (
  <div styleName="row">
    <div styleName="column">
      <p styleName="labelStyle">{label}</p>
    </div>
    <div styleName="column">
      <b>
        {preVal}
      </b>
      <p>{tagA}</p>
    </div>
    <div styleName="column">
      <b>
        {postVal}
      </b>
      <p>{tagB}</p>
    </div>
  </div>
);

const RatioSection = ({ stepRatesData }) => {
  const getValue = (key) => {
    const value = stepRatesData[key] ? `${(Number(stepRatesData[key]) * 100).toFixed(2)}%` : '--';
    return value;
  };
  return (
    <div styleName="ratio-section">
      <div styleName="section1">
        <RenderRatioBlock
          label="Front End Ratio"
          postVal={getValue('proposedFrontEndRatio')}
          preVal={getValue('currentFrontEndRatio')}
          tagA="Current"
          tagB="Proposed"
        />
        <RenderRatioBlock
          label="DTI Percentage"
          postVal={getValue('proposedDTI')}
          preVal={getValue('currentDTI')}
          tagA="Pre Mod"
          tagB="Post Mod"
        />
      </div>
      <div styleName="section2">
        <RenderRatioBlock
          label="Back End Ratio"
          postVal={getValue('proposedBackEndRatio')}
          preVal={getValue('currentBackEndRatio')}
          tagA="Current"
          tagB="Proposed"
        />
        <RenderRatioBlock
          label="LTV Percentage"
          postVal={getValue('preModLTVPercentage')}
          preVal={getValue('postModLTVPercentage')}
          tagA="Pre Mod"
          tagB="Post Mod"
        />
      </div>
    </div>
  );
};

RatioSection.propTypes = {
  stepRatesData: PropTypes.shape({
    currentFrontEndRatio: PropTypes.string,
  }),
};

RatioSection.defaultProps = {
  stepRatesData: {
    currentFrontEndRatio: '--',
  },
};


RenderRatioBlock.defaultProps = {
  label: '',
  preVal: undefined,
  postVal: undefined,
  tagA: undefined,
  tagB: undefined,
};

RenderRatioBlock.propTypes = {
  label: PropTypes.string,
  postVal: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  preVal: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  tagA: PropTypes.string,
  tagB: PropTypes.string,
};

const mapStateToProps = state => ({
  stepRatesData: evalSelectors.ratios(state),
});
export default connect(mapStateToProps, null)(RatioSection);
