/* eslint-disable import/prefer-default-export */
import {
  all, put, takeEvery, select, call,
} from 'redux-saga/effects';
import * as Api from 'lib/Api';
import * as R from 'ramda';
import { SET_RESULT_OPERATION } from 'ducks/dashboard/types';
import { ERROR } from 'constants/common';
import { selectors as repaymentEvalSelectors } from 'ducks/repaymentEval';
import { selectors as evalSelector } from 'ducks/eval';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import { selectors as loginSelectors } from 'ducks/login';
// import { VALID_LOAN_CHNG_TYPES } from 'constants/eval';
// import { getCaseAprvStatus, getChangeEffects }
// from 'lib/CustomFunctions/Repayment/processPaymentData';
import { evalMethods } from 'ducks/eval/sagas';
import { LOSS_MITIGATION_MGR } from '../../../constants/Groups';
import { SET_SNACK_BAR_VALUES } from '../notifications/types';
import {
  SHOW_LOADER, HIDE_LOADER,
  UPDATE_REPAY_TRACKPAYMENT_DATES,
  FETCH_MONTHLY_PAYMENT_DETAILS,
  SET_MONTHLY_PAYMENT_DETAILS,
  SET_REPAYMENT_EVAL_ERROR_MSG,
  LOCK_CALC,
  ENABLE_CALC,
  // FETCH_PAYMENT_CHANGES_DETAILS,
  // SET_PAYMENT_CHANGES_DETAILS,
  SEND_FORAPPROVAL_RULES,
  SEND_FORAPPROVAL,
  REJECT_CASE_RULES,
  FETCH_REJECT_REASONS,
  SET_REJECT_REASONS,
  SET_IS_CASE_REJECTED,
  CALCULATE_REPAYMENT_DATA,
  FETCH_REPAY_DATA_LOADER,
  CALCULATE_LOADER,
  UPDATE_CALC_RESPONSE,
  TOGGLE_LOCK_CASE,
  SAVE_LOCK_CASE,
  UPDATE_MONTHLY_PAYMENT_DETAILS,
} from './types';
import { SET_CASEHEADERINFO_DATA } from '../eval/types';
import { DateFormatter } from '../../../lib/DateUtils';

const getValidationErrorMsg = (validationMsg) => {
  let msg = '';
  if (validationMsg) {
    let errorList = R.pathOr([], ['validationResult', 'messages'], validationMsg);

    if (errorList && errorList.length > 0) {
      errorList = R.sort(R.ascend(R.prop('messageId')), errorList);
      msg = errorList[0].message;
    }
  }

  return msg;
};

function* updateRepayTrackPaymentDates(action) {
  console.log(action);
  yield put({ type: SHOW_LOADER });
  try {
    // TODO -make api call to update trackpayment dates
    const response = {};
    if (response && response.errors) {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'Trackpayments',
          status: 'Failed to update Track payment dates',
        },
      });
    } else {
      // TODO -set trackpayments data from response
      console.log(response);
    }
    yield put({ type: HIDE_LOADER });
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'Trackpayments',
        status: 'Failed to update Track payment dates',
      },
    });
    yield put({ type: HIDE_LOADER });
  }
}

function* validateRepayPreLockCaseRules(action) {
  const caseId = action.payload;
  try {
    const monthlyPaymentDetails = yield select(repaymentEvalSelectors.getMonthlyPaymentDetails);
    const caseSpecificFieldValues = yield select(repaymentEvalSelectors.caseSpecificFieldValues);
    const universalFieldValues = [];
    ['repaymentMonthlyAmount', 'duration', 'startDate', 'delegationIndicator', 'nonDelApprovalDate', 'workoutType']
      .forEach((e) => {
        if (monthlyPaymentDetails[e]) {
          universalFieldValues.push(monthlyPaymentDetails[e]);
        }
      });

    // const caseSpecificFieldValues = [
    //   monthlyPaymentDetails.fees,
    //   monthlyPaymentDetails.advances,
    //   monthlyPaymentDetails.pendingPayment,
    // ];
    const forbearancePlanSchedule = yield select(repaymentEvalSelectors.forbearancePlanSchedule);
    // if (monthlyPaymentDetails.fees) caseSpecificFieldValues.push(monthlyPaymentDetails.fees);
    // if (monthlyPaymentDetails.advances) {
    //   caseSpecificFieldValues.push(monthlyPaymentDetails.advances);
    // }

    // if (monthlyPaymentDetails.pendingPayment) {
    //   caseSpecificFieldValues.push(monthlyPaymentDetails.pendingPayment);
    // }

    const planStartDates = (yield select(repaymentEvalSelectors.planStartDates));
    const isInterestRateChanged = (yield select(repaymentEvalSelectors.isInterestRateChanged));
    const approvalHistory = (yield select(repaymentEvalSelectors.approvalHistory));
    const brand = yield select(dashboardSelectors.brand);
    const user = yield select(loginSelectors.getUser);
    const email = R.path(['userDetails', 'email'], user);

    const preLockPayload = {
      universalFieldValues,
      caseSpecificFieldValues,
      forbearancePlanSchedule,
      planStartDates,
      resolutionId: caseId,
      isInterestRateChanged,
      approvalHistory,
      // TODO - remove hardcoding of selectedGroup
      selectedGroup: 'TEST',
    };
    const headers = {
      Brand: brand, Application: 'CMOD', EmailAddress: email,
    };
    const response = yield call(Api.callPost, 'api/rulesengine/RulesEngine/ValidatePreLockCaseRules', preLockPayload,
      headers);
    if (response && response.validationResult && response.validationResult.isSuccessful) {
      // yield put({ type: SET_RULE_ENGINE_ERROR_MSG, payload: null });
      yield put({
        type: SET_REPAYMENT_EVAL_ERROR_MSG,
        payload: '',
      });
    } else if (response && response.validationResult
      && !response.validationResult.isSuccessful) {
      const msg = getValidationErrorMsg(response);
      yield put({
        type: SET_REPAYMENT_EVAL_ERROR_MSG,
        payload: R.isEmpty(msg) ? 'Error in ValidateLockCaseExtRules' : msg,
      });
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'calculatePreLockRules',
          status: 'Failed to calculate pre lock rules.',
        },
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'calculatePreLockRules',
        status: 'Failed to calculate pre lock rules.',
      },
    });
  }
}

const mapMonthlyPaymentData = (actionData) => {
  if (actionData) {
    const contractData = R.propOr(null, 'contract', actionData);
    if (contractData) {
      const {
        universalFieldValues, caseSpecificFieldValues,
        ...universalActionResp
      } = contractData;

      const {
        forbearancePlanSchedule, forbearancePlanPastDuePayments,
      } = universalActionResp;

      let repaymentMonthlyAmounts = [];
      repaymentMonthlyAmounts = forbearancePlanSchedule && forbearancePlanSchedule.map(item => ({
        ...item,
        description: `Repayment Plan ${item.sequence}`,
        delinquencyPortion: R.pathOr(0, ['delinqPartialAmount'], item),
      }));

      let pastDuePayments = [];
      pastDuePayments = forbearancePlanPastDuePayments
        && forbearancePlanPastDuePayments.map(item => ({
          description: `Past Due Payment ${item.sequence}`,
          date: DateFormatter(item.paymentDate, 'DD MMM YYYY'),
          contractualAmount: item.contractualAmount,
        }));

      const data = {
        duration: R.find(R.propEq('columnName', 'planDuration'))(universalFieldValues),
        repaymentMonthlyAmount: R.find(R.propEq('columnName', 'monthlyRepaymentAmount'))(universalFieldValues),
        startDate: R.find(R.propEq('columnName', 'caseStartDate'))(universalFieldValues),
        delegationIndicator: R.find(R.propEq('columnName', 'LockedDelegateType'))(universalFieldValues),
        nonDelApprovalDate: R.find(R.propEq('columnName', 'nondelapprovaldate'))(universalFieldValues),
        workoutType: R.find(R.propEq('columnName', 'workoutType'))(universalFieldValues),
        isInterestRateChanged: R.propOr(null, 'isInterestRateChanged', contractData),
        caseSpecificFieldValues,
        universalFieldValues,
        universalActionResp,
        repaymentMonthlyAmounts,
        pastDuePayments,
      };
      return data;
    }
  }
  return null;
};

const fetchCaseSpecificExternalFieldValue = (caseSpecificFieldValues, key) => {
  const feesValue = caseSpecificFieldValues.filter(e => e.columnName === 'originalFees');
  const advanceValue = caseSpecificFieldValues.filter(e => e.columnName === 'originalAdvance');
  const pastDuePaymentValue = caseSpecificFieldValues.filter(e => e.columnName === 'totalContractual');
  const suspenseValue = caseSpecificFieldValues.filter(e => e.columnName === 'suspenseBalance');

  const fees = parseFloat(feesValue && feesValue[0]
    && feesValue[0].columnVal ? feesValue[0].columnVal : 0);
  const advance = parseFloat(advanceValue && advanceValue[0]
    && advanceValue[0].columnVal ? advanceValue[0].columnVal : 0);
  const pastDuePayment = parseFloat(pastDuePaymentValue && pastDuePaymentValue[0]
    && pastDuePaymentValue[0].columnVal ? pastDuePaymentValue[0].columnVal : 0);
  const suspense = parseFloat(suspenseValue && suspenseValue[0]
    && suspenseValue[0].columnVal ? suspenseValue[0].columnVal : 0);

  if (key === 'totalDebt') {
    return fees + advance + pastDuePayment;
  }
  if (key === 'fundsInHouse') {
    return fees + advance + pastDuePayment - suspense;
  }

  return 0;
};

function* calculateRepayment(action) {
  yield put({ type: CALCULATE_LOADER, payload: true });
  // const source = R.pathOr(null, ['payload', 'source'], action);
  // if (source !== 'lock') {
  // }
  yield put({
    type: SET_REPAYMENT_EVAL_ERROR_MSG,
    payload: '',
  });
  yield put({
    type: ENABLE_CALC,
    payload: false,
  });
  const actionData = R.pathOr(null, ['payload'], action);
  try {
    const monthlyPaymentDetails = yield select(repaymentEvalSelectors.getMonthlyPaymentDetails);

    let caseSpecificFieldValues = yield select(repaymentEvalSelectors.caseSpecificFieldValues);

    const totalDebt = fetchCaseSpecificExternalFieldValue(caseSpecificFieldValues, 'totalDebt');
    yield put({ type: UPDATE_MONTHLY_PAYMENT_DETAILS, payload: { key: 'totalDebt', value: totalDebt } });

    const fundsInHouse = fetchCaseSpecificExternalFieldValue(caseSpecificFieldValues, 'fundsInHouse');
    yield put({ type: UPDATE_MONTHLY_PAYMENT_DETAILS, payload: { key: 'fundsInHouse', value: fundsInHouse } });

    caseSpecificFieldValues = yield select(repaymentEvalSelectors.caseSpecificFieldValues);

    const universalFieldValues = [];
    if (actionData) {
      Object.keys(actionData).forEach((key) => {
        if (monthlyPaymentDetails[key]) {
          monthlyPaymentDetails[key].columnVal = actionData[key];
          universalFieldValues.push(monthlyPaymentDetails[key]);
        }
      });
    }
    if (monthlyPaymentDetails) {
      // if (monthlyPaymentDetails.fees) caseSpecificFieldValues.push(monthlyPaymentDetails.fees);
      // if (monthlyPaymentDetails.advances) {
      //   caseSpecificFieldValues.push(monthlyPaymentDetails.advances);
      // }
      // if (monthlyPaymentDetails.pendingPayment) {
      //   caseSpecificFieldValues.push(monthlyPaymentDetails.pendingPayment);
      // }

      const brand = yield select(dashboardSelectors.brand);
      const user = yield select(loginSelectors.getUser);
      const email = R.path(['userDetails', 'email'], user);
      const loanId = yield select(dashboardSelectors.loanNumber);

      const caseId = yield select(dashboardSelectors.getRepaymentCaseId);
      const forbearancePlanSchedule = (
        yield select(repaymentEvalSelectors.forbearancePlanSchedule)
      );
      const planStartDates = (yield select(repaymentEvalSelectors.planStartDates));
      const isInterestRateChanged = (yield select(repaymentEvalSelectors.isInterestRateChanged));
      const approvalHistory = (yield select(repaymentEvalSelectors.approvalHistory));

      const preLockPayload = {
        universalFieldValues,
        caseSpecificFieldValues,
        forbearancePlanSchedule,
        planStartDates,
        resolutionId: caseId,
        isInterestRateChanged,
        approvalHistory,
        // TODO - remove hardcoding of selectedGroup
        selectedGroup: 'TEST',
      };
      const headers = {
        Brand: brand, Application: 'CMOD', EmailAddress: email,
      };
      const preLockResponse = yield call(Api.callPost, 'api/rulesengine/RulesEngine/ValidatePreLockCaseRules', preLockPayload,
        headers);

      if (preLockResponse && preLockResponse.validationResult
        && preLockResponse.validationResult.isSuccessful) {
        const contractData = yield select(repaymentEvalSelectors.universalActionResp);

        const calcRequest = {
          ...contractData,
          universalFieldValues,
          caseSpecificFieldValues,
          forbearancePlanSchedule,
          loanId,
          loanIdString: String(loanId),
          brandId: brand,
        };

        const calcResponse = yield call(Api.callPost, '/api/universal-calc/api/UniversalCalc/calculate', calcRequest);

        if (calcResponse && calcResponse.isSuccessful) {
          const data = mapMonthlyPaymentData(calcResponse);
          yield put({ type: UPDATE_CALC_RESPONSE, payload: data });
          // const snackBar = {};
          // snackBar.message = 'Repayment Calculation is Successfull';
          // snackBar.type = 'success';
          // snackBar.open = true;
          // yield put({
          //   type: SET_SNACK_BAR_VALUES,
          //   payload: snackBar,
          // });

          const lockPayload = {
            resolutionId: caseId,
            isInterestRateChanged,
            approvalHistory,
            // TODO - remove hardcoding of selectedGroup
            selectedGroup: 'TEST',
          };
          const lockResponse = yield call(Api.callPost, 'api/rulesengine/RulesEngine/ValidateLockCaseRules', lockPayload,
            headers);

          if (lockResponse && lockResponse.validationResult
            && !lockResponse.validationResult.isSuccessful) {
            const msg = getValidationErrorMsg(lockResponse);
            yield put({
              type: SET_REPAYMENT_EVAL_ERROR_MSG,
              payload: R.isEmpty(msg) ? 'Error in ValidateLockCaseRules' : msg,
            });
          }

          yield put({
            type: ENABLE_CALC,
            payload: true,
          });
        } else {
          let calcErrorList = R.pathOr([], ['messages'], calcResponse);
          let calcErrorMsg = '';

          if (calcErrorList && calcErrorList.length > 0) {
            calcErrorList = R.sort(R.ascend(R.prop('messageId')), calcErrorList);
            calcErrorMsg = calcErrorList[0].message;
          }
          yield put({
            type: SET_REPAYMENT_EVAL_ERROR_MSG,
            payload: R.isEmpty(calcErrorMsg) ? 'Error in Repayment Calculation' : calcErrorMsg,
          });
          yield put({
            type: ENABLE_CALC,
            payload: true,
          });
        }
      } else {
        const msg = getValidationErrorMsg(preLockResponse);
        yield put({
          type: SET_REPAYMENT_EVAL_ERROR_MSG,
          payload: R.isEmpty(msg) ? 'Error in ValidatePreLockCaseRules' : msg,
        });
        yield put({
          type: ENABLE_CALC,
          payload: true,
        });
      }
    } else {
      yield put({
        type: SET_REPAYMENT_EVAL_ERROR_MSG,
        payload: 'Error in updating editable fields',
      });
      yield put({
        type: ENABLE_CALC,
        payload: true,
      });
    }
  } catch (e) {
    // if (source !== 'lock') {
    //   yield put({ type: CALCULATE_LOADER, payload: false });
    // }
    yield put({
      type: SET_REPAYMENT_EVAL_ERROR_MSG,
      payload: 'Failure in Repayment Calculation',
    });
    yield put({
      type: ENABLE_CALC,
      payload: true,
    });
  }
  // if (source !== 'lock') {
  yield put({ type: CALCULATE_LOADER, payload: false });
  // }
}

function* saveLockCase(action) {
  const caseId = yield select(dashboardSelectors.getRepaymentCaseId);
  yield put({ type: TOGGLE_LOCK_CASE, payload: false });
  yield put({ type: CALCULATE_LOADER, payload: true });
  const actionData = R.pathOr(null, ['payload'], action);
  const contractData = yield select(repaymentEvalSelectors.universalActionResp);
  const loanId = yield select(dashboardSelectors.loanNumber);
  const brandId = yield select(dashboardSelectors.brand);
  const user = yield select(loginSelectors.getUser);
  const email = R.path(['userDetails', 'email'], user);
  const monthlyPaymentDetails = yield select(repaymentEvalSelectors.getMonthlyPaymentDetails);
  const caseSpecificFieldValues = yield select(repaymentEvalSelectors.caseSpecificFieldValues);
  const universalFieldValues = [];
  try {
    if (actionData) {
      Object.keys(actionData).forEach((key) => {
        if (monthlyPaymentDetails[key]) {
          monthlyPaymentDetails[key].columnVal = actionData[key];
          universalFieldValues.push(monthlyPaymentDetails[key]);
        }
      });
    }
    const lockRequest = {
      ...contractData,
      universalFieldValues,
      caseSpecificFieldValues,
      loanId,
      loanIdString: String(loanId),
      brandId,
    };
    const lockResponse = yield call(Api.callPost, '/api/universal-action/api/UniversalAction/lock', lockRequest);

    if (lockResponse && lockResponse.isSuccessful === true) {
      yield put({
        type: SET_REPAYMENT_EVAL_ERROR_MSG,
        payload: '',
      });
      const caseHeaderInfoData = yield select(evalSelector.caseHeaderInfoData);
      const evalId = R.pathOr(null, ['evalId'], caseHeaderInfoData);
      const evalType = R.pathOr(null, ['evalType'], caseHeaderInfoData);

      let updateEvalCaseStsPayload;

      if (!evalId || evalId === 0) { // Standalone case
        updateEvalCaseStsPayload = {
          loanId,
          userId: email,
          evalId,
          evalStatus: null,
          evalSubStatus: null,
          caseId,
          caseStatus: 'Locked',
          caseSubStatus: null,
        };
      } else if (evalType === 'Disaster') { // Disaster Repay
        updateEvalCaseStsPayload = {
          loanId,
          userId: email,
          evalId,
          evalStatus: 'Active',
          evalSubStatus: 'Active Forbearance',
          caseId,
          caseStatus: 'Locked',
          caseSubStatus: null,
        };
      }

      const updateStsresponse = yield call(Api.callPost, '/api/dataservice/eval/updateEvalCaseStatus', updateEvalCaseStsPayload);

      if (updateStsresponse && updateStsresponse.isSuccessful === true) {
        yield put({
          type: SET_RESULT_OPERATION,
          payload: {
            level: 'Success',
            saga: 'lock',
            status: 'Repayment case locked successfully',
          },
        });

        const caseHeaderInfo = yield select(evalSelector.caseHeaderInfoData);
        yield put({
          type: SET_CASEHEADERINFO_DATA,
          payload: { ...caseHeaderInfo, status: 'Locked', lockFlag: 1 },
        });
      } else {
        yield put({
          type: SET_REPAYMENT_EVAL_ERROR_MSG,
          payload: 'Failure in Updating Eval-Case status',
        });
      }
    } else {
      yield put({
        type: SET_REPAYMENT_EVAL_ERROR_MSG,
        payload: 'Failure in Locking calculation',
      });
    }
  } catch (e) {
    yield put({
      type: SET_REPAYMENT_EVAL_ERROR_MSG,
      payload: 'Failure in Save Lock calculation',
    });
  }
  yield put({ type: CALCULATE_LOADER, payload: false });
}

function* lockRepaymentCalculation(action) {
  yield call(calculateRepayment, action);
  yield put({ type: CALCULATE_LOADER, payload: true });
  try {
    const errorMessage = yield select(repaymentEvalSelectors.getRuleCheckErrorMessage);

    if (!R.isEmpty(errorMessage)) {
      const snackBar = {};
      snackBar.message = 'Cannot Lock calculation due to Rule Check Error';
      snackBar.type = 'error';
      snackBar.open = true;
      yield put({
        type: SET_SNACK_BAR_VALUES,
        payload: snackBar,
      });
      yield put({ type: CALCULATE_LOADER, payload: false });
      return;
    }

    const monthlyPaymentDetails = yield select(repaymentEvalSelectors.getMonthlyPaymentDetails);
    // const universalFieldValues = [];
    // if (actionData) {
    //   Object.keys(actionData).forEach((key) => {
    //     if (monthlyPaymentDetails[key]) {
    //       monthlyPaymentDetails[key].columnVal = actionData[key];
    //       universalFieldValues.push(monthlyPaymentDetails[key]);
    //     }
    //   });
    // }

    if (monthlyPaymentDetails) {
      // const caseSpecificFieldValues = [
      //   monthlyPaymentDetails.fees,
      //   monthlyPaymentDetails.advances,
      //   monthlyPaymentDetails.pendingPayment,
      // ];


      const brandId = yield select(dashboardSelectors.brand);

      const user = yield select(loginSelectors.getUser);
      const email = R.path(['userDetails', 'email'], user);

      const caseId = yield select(dashboardSelectors.getRepaymentCaseId);
      const isInterestRateChanged = (yield select(repaymentEvalSelectors.isInterestRateChanged));
      const approvalHistory = (yield select(repaymentEvalSelectors.approvalHistory));

      const lockExtPayload = {
        resolutionId: caseId,
        isInterestRateChanged: isInterestRateChanged || false,
        approvalHistory,
        // TODO - remove hardcoding of selectedGroup
        selectedGroup: 'TEST',
      };
      const headers = {
        Brand: brandId, Application: 'CMOD', EmailAddress: email,
      };

      const extResponse = yield call(Api.callPost, 'api/rulesengine/RulesEngine/ValidateLockCaseExtRules', lockExtPayload, headers);

      if (extResponse && extResponse.validationResult
        && extResponse.validationResult.isSuccessful) {
        yield put({
          type: SET_REPAYMENT_EVAL_ERROR_MSG,
          payload: '',
        });
        yield put({ type: TOGGLE_LOCK_CASE, payload: true });
      } else {
        const msg = getValidationErrorMsg(extResponse);
        yield put({
          type: SET_REPAYMENT_EVAL_ERROR_MSG,
          payload: R.isEmpty(msg) ? 'Error in ValidateLockCaseExtRules' : msg,
        });
      }
    }
  } catch (e) {
    yield put({
      type: SET_REPAYMENT_EVAL_ERROR_MSG,
      payload: 'Failure in Locking calculation',
    });
  }
  yield put({ type: CALCULATE_LOADER, payload: false });
}

function* fetchMonthlyPaymentDetails(action) {
  try {
    const caseId = action.payload;
    const response = yield call(Api.callGet, `/api/universal-action/api/UniversalAction?caseId=${caseId}`);
    if (response) {
      yield put({
        type: SET_REPAYMENT_EVAL_ERROR_MSG,
        payload: '',
      });
      const mappedData = mapMonthlyPaymentData(response);

      yield put({
        type: SET_MONTHLY_PAYMENT_DETAILS,
        payload: mappedData,
      });
      yield put({
        type: ENABLE_CALC,
        payload: false,
      });
    }
  } catch (e) {
    yield put({
      type: SET_MONTHLY_PAYMENT_DETAILS,
      payload: {},
    });
  }
}

const fetchValidationMessage = function* fetchValidationMessage(validationMsg) {
  const errors = validationMsg.validationResult.messages;
  let errorMessages = '';
  if (errors.length > 0) {
    if (errors.length === 1) {
      errorMessages = errors[0].detailedMessage ? errors[0].detailedMessage : errors[0].message;
    } else {
      errorMessages = errors.map((error, index) => `${index + 1}. ${error.detailedMessage ? error.detailedMessage : error.message}`).join('\n');
    }

    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'sendforapproval',
        status: errorMessages,
      },
    });
  }
};

function* sendForApprovalRule(action) {
  yield put({ type: CALCULATE_LOADER, payload: true });
  const caseId = R.pathOr(null, ['payload', 'caseId'], action);
  const user = yield select(loginSelectors.getUser);
  const email = R.path(['userDetails', 'email'], user);
  try {
    const response = yield call(Api.callGet, `/api/universal-action/api/UniversalAction?caseId=${caseId}`);
    const contractData = R.propOr(null, 'contract', response);
    if (response && response.isSuccessful && contractData) {
      const brand = yield select(dashboardSelectors.brand);
      const isInterestRateChanged = R.pathOr(null, ['isInterestRateChanged'], contractData);
      const approvalHistory = R.pathOr(null, ['approvalHistory'], contractData);
      const approvalPayload = {
        resolutionId: caseId,
        isInterestRateChanged,
        approvalHistory,
        selectedGroup: 'TEST', // TO DO
      };

      const headers = {
        Brand: brand, Application: 'CMOD', EmailAddress: email,
      };
      const ruleResponse = yield call(Api.callPost, 'api/rulesengine/RulesEngine/ValidateSendForApprovalRules', approvalPayload, headers);
      if (ruleResponse && ruleResponse.validationResult
        && ruleResponse.validationResult.isSuccessful) {
        const approvalActionRequest = R.assoc('sentForApproval', 1, contractData);
        const approvalActionResponse = yield call(Api.callPost, '/api/universal-action/api/UniversalAction/sendforapproval', approvalActionRequest);
        if (approvalActionResponse && approvalActionResponse.isSuccessful) {
          const loanId = yield select(dashboardSelectors.loanNumber);
          const caseHeaderInfoData = yield select(evalSelector.caseHeaderInfoData);
          const evalId = R.pathOr(null, ['evalId'], caseHeaderInfoData);
          const evalType = R.pathOr(null, ['evalType'], caseHeaderInfoData);
          let evalCaseStatusPayload;

          if (!evalId || evalId === 0) { // Standalone case
            evalCaseStatusPayload = {
              loanId,
              userId: email,
              evalId,
              caseId,
              caseStatus: 'Sent for Approval',
              caseSubStatus: null,
            };
          } else if (evalType === 'Disaster' || evalType === 'Pandemic') { // Disaster || PandemicRepay
            evalCaseStatusPayload = {
              loanId,
              userId: email,
              evalId,
              evalStatus: 'Active',
              evalSubStatus: 'Active Forbearance',
              caseId,
              caseStatus: 'Sent for Approval',
              caseSubStatus: null,
            };
          }

          const updateStsresponse = yield call(Api.callPost, '/api/dataservice/eval/updateEvalCaseStatus', evalCaseStatusPayload);

          if (updateStsresponse && updateStsresponse.isSuccessful) {
            // Add Refresh Call
            yield call(evalMethods.getCaseHeaderInfoData, { payload: { caseId } });
            yield put({
              type: SET_RESULT_OPERATION,
              payload: {
                level: 'Success',
                saga: 'sendforapproval',
                status: 'Repayment case Successful for send for approval',
              },
            });
          } else {
            yield put({
              type: SET_RESULT_OPERATION,
              payload: {
                level: ERROR,
                saga: 'sendforapproval',
                status: 'Failed to Update Eval-Case status for send for approval case',
              },
            });
          }
        } else if (approvalActionResponse && !approvalActionResponse.isSuccessful) {
          yield call(fetchValidationMessage,
            { validationResult: { messages: ruleResponse.messages } });
        }
      } else if (ruleResponse && ruleResponse.validationResult
        && !ruleResponse.validationResult.isSuccessful) {
        yield call(fetchValidationMessage, ruleResponse);
      }
    } else if (response && !response.isSuccessful) {
      yield call(fetchValidationMessage,
        { validationResult: { messages: response.messages } });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'sendforapproval',
        status: 'Failed to update send for approval',
      },
    });
  }
  yield put({ type: CALCULATE_LOADER, payload: false });
}

function* sendForApproval(action) {
  yield put({ type: CALCULATE_LOADER, payload: true });
  const caseId = R.pathOr(null, ['payload', 'caseId'], action);
  try {
    const response = yield call(Api.callGet, `/api/universal-action/api/UniversalAction?caseId=${caseId}`);
    const contractData = R.propOr(null, 'contract', response);

    const ruleResponse = yield call(Api.callPost, '/api/universal-action/api/UniversalAction/approve', contractData);
    if (ruleResponse && ruleResponse.isSuccessful) {
      const { resolutionId, isInterestRateChanged, approvalHistory } = contractData;
      const validateApproveCaseRequest = {
        resolutionId,
        isInterestRateChanged,
        approvalHistory,
      };

      const brand = yield select(dashboardSelectors.brand);
      const user = yield select(loginSelectors.getUser);
      const email = R.path(['userDetails', 'email'], user);
      const headers = {
        Brand: brand, Application: 'CMOD', EmailAddress: email,
      };

      const validateApproveCaseResponse = yield call(Api.callPost, '/api/rulesengine/RulesEngine/ValidateApproveCaseRules', validateApproveCaseRequest, headers);
      if (validateApproveCaseResponse && validateApproveCaseResponse.validationResult
        && validateApproveCaseResponse.validationResult.isSuccessful) {
        const loanId = yield select(dashboardSelectors.loanNumber);
        const caseHeaderInfoData = yield select(evalSelector.caseHeaderInfoData);
        const evalId = R.pathOr(null, ['evalId'], caseHeaderInfoData);
        const evalType = R.pathOr(null, ['evalType'], caseHeaderInfoData);
        let evalCaseStatusPayload;

        if (!evalId || evalId === 0) { // Standalone case
          evalCaseStatusPayload = {
            loanId,
            userId: email,
            evalId,
            caseId,
            caseStatus: 'Approved',
            caseSubStatus: null,
          };
        } else if (evalType === 'Disaster' || evalType === 'Pandemic') { // Disaster || PandemicRepay
          evalCaseStatusPayload = {
            loanId,
            userId: email,
            evalId,
            evalStatus: 'Active',
            evalSubStatus: 'Active Forbearance',
            caseId,
            caseStatus: 'Approved',
            caseSubStatus: null,
          };
        }

        const updateStsresponse = yield call(Api.callPost, '/api/dataservice/eval/updateEvalCaseStatus', evalCaseStatusPayload);

        if (updateStsresponse && updateStsresponse.isSuccessful) {
          yield call(evalMethods.getCaseHeaderInfoData, { payload: { caseId } });
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              level: 'Success',
              saga: 'Approved',
              status: 'Repayment case approved successfully',
            },
          });
        } else {
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              level: ERROR,
              saga: 'Approved',
              status: 'Failed to Update Eval-Case status to Approved',
            },
          });
        }
      } else if (validateApproveCaseResponse && validateApproveCaseResponse.validationResult
        && !validateApproveCaseResponse.isSuccessful) {
        yield call(fetchValidationMessage,
          { validationResult: { messages: ruleResponse.messages } });
      }
    } else if (ruleResponse && !ruleResponse.isSuccessful) {
      yield call(fetchValidationMessage, ruleResponse);
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'Approved',
        status: 'Failed to update send for approval',
      },
    });
  }
  yield put({ type: CALCULATE_LOADER, payload: false });
}

function* getRejectReasons(action) {
  try {
    const resolutionTypeId = R.pathOr(null, ['payload', 'resolutionTypeId'], action);
    const status = R.pathOr(null, ['payload', 'status'], action);
    const response = yield call(Api.callGet, `/api/dataservice/eval/resolutionRejectReason/${resolutionTypeId}?evalStatus=${status}`);
    if (response) {
      yield put({
        type: SET_REJECT_REASONS,
        payload: response,
      });
    }
  } catch (e) {
    yield put({
      type: SET_REJECT_REASONS,
      payload: {
        level: ERROR,
        saga: 'Rejectreason',
        status: 'Service down while fetching reject reason',
      },
    });
  }
}


function* rejectCaseRules(action) {
  yield put({ type: FETCH_REPAY_DATA_LOADER, payload: true });
  const caseId = R.pathOr(null, ['payload', 'caseId'], action);
  const rejectId = R.pathOr(null, ['payload', 'rejectId'], action);
  const reason = R.pathOr(null, ['payload', 'reason'], action);
  const loanId = yield select(dashboardSelectors.loanNumber);
  const brandId = yield select(dashboardSelectors.brand);
  const user = yield select(loginSelectors.getUser);
  const email = R.path(['userDetails', 'email'], user);
  // const userId = yield call(Api.callGet, `/api/tkams/eval/getUserId/${email}`);
  const userId = email;
  const caseHeaderInfo = yield select(evalSelector.caseHeaderInfoData);
  const caseType = R.pathOr(null, ['caseType'], caseHeaderInfo);

  let approvalLevel;

  const groupsList = R.pathOr([], ['groupList'], user);
  groupsList.forEach((group) => {
    if (group.includes(LOSS_MITIGATION_MGR)) {
      approvalLevel = 'Manager';
    } else if (group.includes('vp')) {
      approvalLevel = 'VP';
    } else if (group.includes('avp')) {
      approvalLevel = 'AVP';
    } else {
      approvalLevel = 'Agent';
    }
  });
  try {
    yield put({ type: SET_IS_CASE_REJECTED, payload: true });
    const response = yield call(Api.callGet, `/api/universal-action/api/UniversalAction?caseId=${caseId}`);
    const contractData = R.propOr(null, 'contract', response);
    if (response && response.isSuccessful && contractData) {
      const brand = yield select(dashboardSelectors.brand);
      // const isInterestRateChanged = R.pathOr(null, ['isInterestRateChanged'], contractData);
      // const approvalHistory = R.pathOr(null, ['approvalHistory'], contractData);
      const approvalHistory = {
        CaseTableId: null,
        CaseId: caseId,
        CaseType: 'Repayment Plan',
        UId: email,
        Lvl: null,
        ApprovalType: null,
        ActionDate: null,
        CategoryId: null,
        Comment: `${rejectId}_${reason}`,
        SubComment: null,
        ReSubmit: null,
        ReasonCode: rejectId,
        SubReasonCode: null,
        ReasonType: null,
        IsManuallyCreated: null,
        SourceId: null,
      };
      const rejectCaseRulesPayload = {
        resolutionId: caseId,
        isInterestRateChanged: false,
        approvalHistory,
        selectedGroup: 'Repayment Plan',
      };

      const headers = {
        Brand: brand, Application: 'CMOD', EmailAddress: email,
      };
      const ruleResponse = yield call(Api.callPost, 'api/rulesengine/RulesEngine/ValidateRejectCaseRules', rejectCaseRulesPayload, headers);
      if (ruleResponse && ruleResponse.validationResult
        && ruleResponse.validationResult.isSuccessful) {
        const hampRejectCaseWithReasonReq = [{
          caseId,
          dispositionComment: null,
          rejReasonId: rejectId,
          rejSubReasonId: null,
          aprvLvl: approvalLevel,
          userName: email,
          userId,
          caseType,
          loanNumber: loanId,
          brandName: brandId,
        }];

        const dispositionResponse = yield call(Api.callPost, '/api/disposition/hampRejectCaseWithReason', hampRejectCaseWithReasonReq);
        const { result } = dispositionResponse;
        if (dispositionResponse && result === 'Success') {
          const caseHeaderInfoData = yield select(evalSelector.caseHeaderInfoData);
          const evalId = R.pathOr(null, ['evalId'], caseHeaderInfoData);
          const disasterFlag = R.pathOr(null, ['disasterFlag'], caseHeaderInfoData);
          let evalCaseStatusPayload = null;

          if (!evalId || evalId === 0) { // Standalone case
            evalCaseStatusPayload = {
              loanId,
              userId: email,
              evalId,
              caseId,
              caseStatus: 'Rejected',
              caseSubStatus: null,
            };
          } else if (disasterFlag === 'E' || disasterFlag === 'PE') {
            evalCaseStatusPayload = {
              loanId,
              userId: email,
              evalId,
              evalStatus: 'Active',
              evalSubStatus: 'Active Forbearance',
              caseId,
              caseStatus: 'Rejected',
              caseSubStatus: null,
            };
          }

          if (evalCaseStatusPayload !== null) {
            const updateStsresponse = yield call(Api.callPost, '/api/dataservice/eval/updateEvalCaseStatus', evalCaseStatusPayload);

            if (updateStsresponse && updateStsresponse.isSuccessful) {
              yield call(evalMethods.getCaseHeaderInfoData, { payload: { caseId } });
              yield put({
                type: SET_RESULT_OPERATION,
                payload: {
                  level: 'Success',
                  saga: 'rejectCaseRules',
                  status: `${caseType} case rejected successfully`,
                },
              });
            } else {
              yield put({
                type: SET_RESULT_OPERATION,
                payload: {
                  level: ERROR,
                  saga: 'rejectCaseRules',
                  status: 'Failed to Update Eval-Case status for reject case',
                },
              });
            }
          } else {
            yield call(evalMethods.getCaseHeaderInfoData, { payload: { caseId } });
            yield put({
              type: SET_RESULT_OPERATION,
              payload: {
                level: 'Success',
                saga: 'rejectCaseRules',
                status: `${caseType} case rejected successfully`,
              },
            });
          }
        } else {
          yield call(fetchValidationMessage, ruleResponse);
          yield put({ type: SET_IS_CASE_REJECTED, payload: false });
        }
      } else {
        yield call(fetchValidationMessage, ruleResponse);
        yield put({ type: SET_IS_CASE_REJECTED, payload: false });
      }
    } else {
      yield call(fetchValidationMessage,
        { validationResult: { messages: response.messages } });
      yield put({ type: SET_IS_CASE_REJECTED, payload: false });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'rejectCaseRules',
        status: 'Failed to update reject case rules',
      },
    });
    yield put({ type: SET_IS_CASE_REJECTED, payload: false });
  }
  yield put({ type: FETCH_REPAY_DATA_LOADER, payload: false });
}

function* watchUpdateRepayTrackPaymentDates() {
  yield takeEvery(UPDATE_REPAY_TRACKPAYMENT_DATES, updateRepayTrackPaymentDates);
}

function* watchFetchMonthlyPaymentDetails() {
  yield takeEvery(FETCH_MONTHLY_PAYMENT_DETAILS, fetchMonthlyPaymentDetails);
}

function* watchLockCalculation() {
  yield takeEvery(LOCK_CALC, lockRepaymentCalculation);
}

function* watchSendForApproval() {
  yield takeEvery(SEND_FORAPPROVAL_RULES, sendForApprovalRule);
}

function* watchSendApproval() {
  yield takeEvery(SEND_FORAPPROVAL, sendForApproval);
}

function* watchResolutinRejectReason() {
  yield takeEvery(FETCH_REJECT_REASONS, getRejectReasons);
}

function* watchRejectCaseRules() {
  yield takeEvery(REJECT_CASE_RULES, rejectCaseRules);
}

function* watchcalculateRepayment() {
  yield takeEvery(CALCULATE_REPAYMENT_DATA, calculateRepayment);
}

function* watchSaveLockCase() {
  yield takeEvery(SAVE_LOCK_CASE, saveLockCase);
}

export const repayModuleMethods = {
  getValidationErrorMsg,
  fetchMonthlyPaymentDetails,
  validateRepayPreLockCaseRules,
};


export const combinedSaga = function* combinedSaga() {
  yield all([
    watchLockCalculation(),
    watchUpdateRepayTrackPaymentDates(),
    watchFetchMonthlyPaymentDetails(),
    watchSendForApproval(),
    watchSendApproval(),
    watchRejectCaseRules(),
    watchResolutinRejectReason(),
    watchcalculateRepayment(),
    watchSaveLockCase(),
  ]);
};
