
const FETCH_HISTORY_DATA = 'app/boardingTemplate/FETCH_HISTORY_DATA';
const SET_HISTORY_DATA = 'app/boardingTemplate/SET_HISTORY_DATA';
const SET_DISTINCT_USERNAMES = 'app/boardingTemplate/SET_DISTINCT_USERNAMES';
const BOARDING_TEMPLATE_SPEC = 'app/boardingTemplate/BOARDING_TEMPLATE_SPEC';
const BOARDING_TEMPLATE_SUBMIT = 'app/boardingTemplate/BOARDING_TEMPLATE_SUBMIT';
const ONBOARD_TEMPLATE_DATA = 'app/boardingTemplate/ONBOARD_TEMPLATE_DATA';
const STORE_BOARDING_DATA = 'app/boardingTemplate/STORE_BOARDING_DATA';
const SET_EXCEPTION_DATA = 'app/boardingTemplate/SET_EXCEPTION_DATA';
const PROCESS_BOARDING_TEMPLATE = 'app/boardingTemplate/PROCESS_BOARDING_TEMPLATE';
const SAVE_PROCESSED_FILE = 'app/boardingTemplate/SAVE_PROCESSED_FILE';
const SET_PROCESS_STATUS = 'app/boardingTemplate/SET_PROCESS_STATUS';
const INITIATE_IMPORT_PROCESS = 'app/boardingTemplate/INITIATE_IMPORT_PROCESS';
const SET_CASE_TYPE_OPTIONS = 'app/boardingTemplate/SET_CASE_TYPE_OPTIONS';
const CASE_TYPES_OPTION = 'app/boardingTemplate/CASE_TYPES_OPTION';
const SET_BOARDING_PHASES = 'app/boardingTemplate/SET_BOARDING_PHASES';
const BOARDING_PHASES = 'app/boardingTemplate/BOARDING_PHASES';
const STORE_UPLOADED_DATA = 'app/boardingTemplate/STORE_UPLOADED_DATA';
const DOWNLOAD_FILE = 'app/boardingTemplate/DOWNLOAD_FILE';

export {
  FETCH_HISTORY_DATA,
  SET_HISTORY_DATA,
  BOARDING_TEMPLATE_SPEC,
  BOARDING_TEMPLATE_SUBMIT,
  ONBOARD_TEMPLATE_DATA,
  STORE_BOARDING_DATA,
  SET_EXCEPTION_DATA,
  PROCESS_BOARDING_TEMPLATE,
  SAVE_PROCESSED_FILE,
  INITIATE_IMPORT_PROCESS,
  SET_CASE_TYPE_OPTIONS,
  CASE_TYPES_OPTION,
  SET_BOARDING_PHASES,
  BOARDING_PHASES,
  STORE_UPLOADED_DATA,
  DOWNLOAD_FILE,
  SET_PROCESS_STATUS,
  SET_DISTINCT_USERNAMES,
};
