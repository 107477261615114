/* eslint-disable import/prefer-default-export */
import {
  takeEvery, all, put,
  call, select,
} from 'redux-saga/effects';
import * as R from 'ramda';
import * as Api from 'lib/Api';
import {
  SHOW_LOADER,
  HIDE_LOADER,
  SET_RESULT_OPERATION,
} from 'ducks/dashboard/types';
// import {
//   ERROR,
// } from 'constants/common';
import * as XLSX from 'xlsx';
import { NUMBER_FIELDS, DATE_FIELDS, DECIMAL_FIELDS } from 'constants/BoardingTemplate';
import DashboardModel from '../../../models/Dashboard';
import {
  FETCH_HISTORY_DATA,
  SET_HISTORY_DATA,
  BOARDING_TEMPLATE_SPEC,
  BOARDING_TEMPLATE_SUBMIT,
  ONBOARD_TEMPLATE_DATA,
  STORE_BOARDING_DATA,
  SET_EXCEPTION_DATA,
  PROCESS_BOARDING_TEMPLATE,
  INITIATE_IMPORT_PROCESS,
  SAVE_PROCESSED_FILE,
  SET_CASE_TYPE_OPTIONS,
  CASE_TYPES_OPTION,
  SET_BOARDING_PHASES,
  BOARDING_PHASES,
  STORE_UPLOADED_DATA,
  DOWNLOAD_FILE,
  SET_PROCESS_STATUS,
  SET_DISTINCT_USERNAMES,
} from './types';
import processExcel from '../../../lib/excelParser';
import { selectors as loginSelectors } from '../login';
import selectors from './selectors';

const {
  Messages:
  {
    LEVEL_FAILED,
    MSG_FILE_UPLOAD_FAILURE,
    MSG_FILE_DOWNLOAD_FAILURE,
  },
} = DashboardModel;

const onDownloadFile = function* onDownloadFile(action) {
  const fileData = action.payload;
  const columnHeaders = fileData.columnHeaders ? fileData.columnHeaders : '';

  const processData = data => data.map((item) => {
    const processedItem = { ...item };
    Object.keys(processedItem).forEach((key) => {
      if (Array.isArray(processedItem[key])) {
        processedItem[key] = processedItem[key].join(', ');
      }
    });
    return processedItem;
  });

  function fitToColumn(arrayOfArray, type) {
    if (type === 'body') {
      const columnsArray = Object.keys(arrayOfArray[0]);
      return columnsArray.map((a, i) => (
        { wch: columnsArray[i].length + 5 }
      ));
    }
    return arrayOfArray[0].map((a, i) => (
      { wch: Math.max(...arrayOfArray.map(a2 => (a2[i] ? a2[i].toString().length : 0))) }
    ));
  }

  /*  eslint no-param-reassign: "error" */
  function formatText(worksheet) {
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    const { r: rowStart, c: colStart } = range.s;
    const { r: rowEnd, c: colEnd } = range.e;

    const numberColumns = new Set();
    const dateColumns = new Set();
    const decimalColumns = new Set();

    for (let c = colStart; c <= colEnd; c += 1) {
      const headerCell = XLSX.utils.encode_cell({ c, r: rowStart });
      const headerValue = worksheet[headerCell].v;

      if (NUMBER_FIELDS.includes(headerValue)) {
        numberColumns.add(c);
      }

      if (DATE_FIELDS.includes(headerValue)) {
        dateColumns.add(c);
      }

      if (DECIMAL_FIELDS.includes(headerValue)) {
        decimalColumns.add(c);
      }
    }

    /* eslint-disable-next-line no-plusplus */
    for (let r = rowStart + 1; r <= rowEnd; r++) {
      /* eslint-disable-next-line no-plusplus */
      for (let c = colStart; c <= colEnd; c++) {
        const cellName = XLSX.utils.encode_cell({ c, r });
        const cell = worksheet[cellName];
        if (cell) {
          if (numberColumns.has(c)) {
            cell.t = 'n';
            cell.z = '0';
          } else if (decimalColumns.has(c)) {
            cell.t = 'n';
            cell.z = '0.0000';
          } else if (dateColumns.has(c)) {
            if (cell.v) {
              const dateValue = new Date(cell.v);
              if (dateValue) {
                dateValue.setHours(0, 0, 0, 0);
                cell.t = 'd';
                cell.v = dateValue.toLocaleString();
              }
            }
          } else {
            cell.z = '@';
          }
        }
      }
    }
  }


  try {
    let ws;
    if (columnHeaders !== '') {
      ws = XLSX.utils.aoa_to_sheet(columnHeaders);
      ws['!cols'] = fitToColumn(columnHeaders, 'header');
    } else {
      const processedData = processData(fileData.data);
      ws = XLSX.utils.json_to_sheet(processedData);
      ws['!cols'] = fitToColumn(processedData, 'body');
    }
    formatText(ws);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileData.fileName);
    XLSX.writeFile(wb, fileData.fileName);
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        status: 'Excel File Downloaded Successfully',
        level: 'Success',
      },
    });
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        status: MSG_FILE_DOWNLOAD_FAILURE,
        level: LEVEL_FAILED,
      },
    });
  }
};

function* gatherHistoryData(action) {
  const {
    pageNumber, pageSize, filter, order,
  } = action.payload;
  try {
    yield put({ type: SHOW_LOADER });
    const requestBody = {};
    if (order && !R.isEmpty(order)) {
      requestBody.boardingTemplateSort = order;
    }
    if (filter && !R.isEmpty(filter)) {
      requestBody.boardingTemplateFilter = filter;
    }
    yield put({
      type: BOARDING_TEMPLATE_SPEC,
      payload: requestBody,
    });
    const response = yield call(Api.callPost, `/api/boardingservice/boarding/getBoardingHistory?pageNumber=${pageNumber}&pageSize=${pageSize}`, requestBody);
    if (R.has('statusCode', response) && response.statusCode === 204) {
      yield put({
        type: SET_HISTORY_DATA,
        payload: {},
      });
      yield put({ type: HIDE_LOADER });
      return;
    }
    let userNames = yield select(selectors.getDistinctUserNames);
    if (response) {
      yield put({
        type: SET_HISTORY_DATA,
        payload: response,
      });
      if (userNames.length === 0) {
        userNames = [...new Set(response.records.map(item => item.createdBy))];
        yield put({
          type: SET_DISTINCT_USERNAMES,
          payload: userNames,
        });
      }
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          status: 'Failed to fetch Data!',
          level: LEVEL_FAILED,
        },
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        status: 'Something went wrong while fetching Data!',
        level: LEVEL_FAILED,
      },
    });
  }
}

function* validateBoardingTemplate() {
  try {
    const processedFileData = yield select(selectors.getProcesseFiledData);
    yield put({
      type: STORE_UPLOADED_DATA,
      payload: processedFileData.uploadRequest || [],
    });
    yield put({ type: SHOW_LOADER });
    const validateResponse = yield call(Api.callPost, '/api/boardingservice/boarding/validate', processedFileData);
    if (validateResponse.statusCode === 200) {
      const { validUploads, invalidResponses } = validateResponse;
      const boardFileRequest = {
        fileName: processedFileData.fileName, uploadRequest: validUploads,
      };
      const boardFileResponse = validUploads.length > 0 ? yield call(Api.callPost, '/api/boardingservice/boarding/boardFile', boardFileRequest) : [];
      const response = [...invalidResponses, ...boardFileResponse];
      yield put({
        type: SET_EXCEPTION_DATA,
        payload: response || [],
      });
      yield put({ type: HIDE_LOADER });
    } else {
      console.error('Something went wrong while validating boarding template data ');
      yield put({
        type: SET_EXCEPTION_DATA,
        payload: [],
      });
      yield put({ type: HIDE_LOADER });
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: 'Error',
          status: 'Something went wrong',
        },
      });
    }
  } catch (e) {
    console.error('Something went wrong while boarding template data ', e);
    yield put({
      type: SET_EXCEPTION_DATA,
      payload: [],
    });
    yield put({ type: HIDE_LOADER });
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: 'Error',
        status: 'Something went wrong',
      },
    });
  }
}

function* processFile(action) {
  const { onboardTemplate, file } = action.payload;
  const filePayload = {};
  try {
    yield put({
      type: SET_PROCESS_STATUS,
      payload: false,
    });
    if (file) {
      const data = yield call(processExcel, file);
      if (data) {
        let errorMessage;
        const jsonData = JSON.parse(data);
        const dateOptions = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        };
        const formattedJsonData = jsonData.map((json) => {
          Object.keys(json).forEach((key) => {
            if (DATE_FIELDS.includes(key) && json[key]) {
              if (typeof json[key] === 'number') {
                const excelDateSerial = Math.floor(json[key]);
                const date = new Date((excelDateSerial - 25569) * 86400 * 1000);
                json[key] = date.toLocaleDateString('en-US', dateOptions); // Formats as 'MM/DD/YYYY'
              }
            }
          });
          return json;
        });
        filePayload.uploadRequest = formattedJsonData;
        filePayload.fileName = file.name;
        filePayload.userEmail = yield select(loginSelectors.getUserPrincipalName);
        const loanIds = R.pluck('Loan ID', formattedJsonData);
        const loanSet = new Set(loanIds);
        const keysToPick = ['Case Type', 'Phase', 'Pre Approved', 'InFlight'];
        const normalizeValues = obj => R.map(value => String(value), obj);
        const jsonDataKeyValues = R.map(R.pick(keysToPick), formattedJsonData).map(normalizeValues);
        const onboardTemplateKeyValues = R.map(R.pick(keysToPick), onboardTemplate)
          .map(normalizeValues);
        const areMandateKeyValuesSame = jsonDataKeyValues.every(
          jsonRow => onboardTemplateKeyValues
            .some(templateRow => R.equals(jsonRow, templateRow)),
        );
        let duplicates = loanIds.filter((item, index) => loanIds.indexOf(item) !== index);
        duplicates = Array.from(new Set(duplicates));
        const templateKeys = [...new Set(onboardTemplate.flatMap(Object.keys))];
        const uploadedFileKeys = [...new Set(formattedJsonData.flatMap(Object.keys))];
        if (formattedJsonData.length > 500) {
          errorMessage = 'The uploaded file exceeds the 500-loan limit. Please correct and try again.';
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              level: 'Error',
              status: errorMessage,
              isUploadCompleted: false,
            },
          });
          return;
        }

        if (formattedJsonData.length !== loanSet.size) {
          errorMessage = `This file cannot be uploaded because duplicate loan numbers ${duplicates} have been provided. Please correct and try again.`;
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              level: 'Error',
              status: errorMessage,
              isUploadCompleted: false,
            },
          });
          return;
        }

        if (onboardTemplate.length !== 0 && !areMandateKeyValuesSame) {
          errorMessage = 'This file cannot be uploaded because the Case Type, Phase, Pre Approved, and Inflight columns must be the same. Please correct and try again.';
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              level: 'Error',
              status: errorMessage,
              isUploadCompleted: false,
            },
          });
          return;
        }

        if (onboardTemplate.length !== 0 && (templateKeys.length !== uploadedFileKeys.length)) {
          errorMessage = 'This file cannot be uploaded, because there is an unrecognized column provided on template. Please correct and try again.';
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              level: 'Error',
              status: errorMessage,
              isUploadCompleted: false,
            },
          });
          return;
        }

        yield put({
          type: SAVE_PROCESSED_FILE,
          payload: filePayload,
        });

        yield put({
          type: BOARDING_TEMPLATE_SUBMIT,
        });

        yield put({
          type: SET_PROCESS_STATUS,
          payload: true,
        });
      }
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          status: 'Excel file uploaded successfully',
          level: 'Success',
        },
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        status: MSG_FILE_UPLOAD_FAILURE,
        level: 'Failed',
      },
    });
  }
}

function* onBoardTemplateData(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(Api.callPost, '/api/boardingservice/boarding/downloadBoardingExcel', action.payload);
    yield put({
      type: STORE_BOARDING_DATA,
      payload: response || [],
    });
    yield put({ type: HIDE_LOADER });
  } catch (e) {
    console.error('Something went wrong while on boarding template data ', e);
    yield put({
      type: STORE_BOARDING_DATA,
      payload: [],
    });
  }
}

function* initiateImportProcess(action) {
  const user = yield select(loginSelectors.getUser);
  const processedFileData = yield select(selectors.getProcesseFiledData);
  const reqBody = {
    fileName: processedFileData.fileName,
    userEmail: processedFileData.userEmail,
    uploadRequest: action.payload,
    userName: user.userDetails.name,
  };
  const response = yield call(Api.callPost, '/api/boardingservice/boarding/importBoardingTemplate', reqBody);
  if (response.status === 200) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        status: 'success',
      },
    });
  }
}

function* setCaseTypeOptions() {
  try {
    const response = yield call(Api.callGet, '/api/boardingservice/boarding/getCaseTypes');
    yield put({
      type: CASE_TYPES_OPTION,
      payload: response,
    });
    if (!response) {
      yield put({
        type: CASE_TYPES_OPTION,
        payload: [],
      });
    }
  } catch (e) {
    yield put({
      type: CASE_TYPES_OPTION,
      payload: [],
    });
  }
}

function* setBoardingPhases(action) {
  try {
    const caseType = action.payload;
    const response = yield call(Api.callGet, `/api/boardingservice/boarding/getBoardingPhases/${caseType}`);
    yield put({
      type: BOARDING_PHASES,
      payload: response,
    });
    if (!response) {
      yield put({
        type: BOARDING_PHASES,
        payload: [],
      });
    }
  } catch (e) {
    yield put({
      type: BOARDING_PHASES,
      payload: [],
    });
  }
}

function* watchFetchHistoryDetails() {
  yield takeEvery(FETCH_HISTORY_DATA, gatherHistoryData);
}

function* watchValidateBoardingTemplate() {
  yield takeEvery(BOARDING_TEMPLATE_SUBMIT, validateBoardingTemplate);
}

function* watchOnBoardTemplateData() {
  yield takeEvery(ONBOARD_TEMPLATE_DATA, onBoardTemplateData);
}

function* watchProcessFile() {
  yield takeEvery(PROCESS_BOARDING_TEMPLATE, processFile);
}

function* watchInitiateImportProcess() {
  yield takeEvery(INITIATE_IMPORT_PROCESS, initiateImportProcess);
}

function* watchSetCaseTypeOptions() {
  yield takeEvery(SET_CASE_TYPE_OPTIONS, setCaseTypeOptions);
}

function* watchSetBoardingPhases() {
  yield takeEvery(SET_BOARDING_PHASES, setBoardingPhases);
}

function* watchOnDownloadFile() {
  yield takeEvery(DOWNLOAD_FILE, onDownloadFile);
}

export const combinedSaga = function* combinedSaga() {
  yield all([
    watchFetchHistoryDetails(),
    watchValidateBoardingTemplate(),
    watchOnBoardTemplateData(),
    watchProcessFile(),
    watchInitiateImportProcess(),
    watchSetCaseTypeOptions(),
    watchSetBoardingPhases(),
    watchOnDownloadFile(),
  ]);
};
