import React from 'react';
import TextField from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import * as R from 'ramda';
import '../BoardingTemplatePage.css';
import { PropTypes } from 'prop-types';
// import { useDispatch } from 'react-redux';
import CaseTypeDropDown from '../DropDowns/CaseTypeDropDown';
import BoardingPhaseDropDown from '../DropDowns/BoardingPhaseDropDown';
import InFlightToggleSwitch from '../ToggleSwitch/InFlightToggleSwitch';
import PreApprovedToggleSwitch from '../ToggleSwitch/PreApprovedToggleSwitch';

const LeftPane = ({
  onGo, isSubmitDisabled, setIsSubmitDisabled, caseTypeOptions, excpMessage,
  selectedCaseType, setSelectedCaseType, boardingPhaseOptions,
  selectedPhase, setSelectedPhase, preApprovedStatus, setPreApprovedStatus,
  inFlightStatus, setInFlightStatus, loanIds, setLoanIds, setBoardingPhasesOperation,
}) => {
  // const dispatch = useDispatch();

  const handleLoanIdsChange = (event) => {
    const re = /[a-zA-Z]|[~`(@!#$%^&*+._)=\-[\]\\';/{}|\\":<>?]/;
    const newLoanIds = event.target.value;
    if (newLoanIds === '' || !re.test(newLoanIds)) {
      setLoanIds(newLoanIds);
      setIsSubmitDisabled(!(newLoanIds.trim()
      && !R.isEmpty(selectedPhase) && !R.isEmpty(selectedCaseType)
      && (inFlightStatus || preApprovedStatus)
      ));
    }
  };

  const handleGo = () => {
    onGo();
  };

  return (
    <>
      <div styleName="status-details-parent">
        <div styleName="loan-numbers">
          <span styleName="typography">
            {'Case Type'}
          </span>
        </div>
        <CaseTypeDropDown
          caseTypeOptions={caseTypeOptions}
          inFlightStatus={inFlightStatus}
          loanIds={loanIds}
          preApprovedStatus={preApprovedStatus}
          selectedCaseType={selectedCaseType}
          selectedPhase={selectedPhase}
          setBoardingPhasesOperation={setBoardingPhasesOperation}
          setIsSubmitDisabled={setIsSubmitDisabled}
          setSelectedCaseType={setSelectedCaseType}
          setSelectedPhase={setSelectedPhase}
        />
        <div styleName="loan-numbers">
          <span styleName="typography">
            {'Boarding Phase'}
          </span>
        </div>
        <BoardingPhaseDropDown
          boardingPhaseOptions={boardingPhaseOptions}
          inFlightStatus={inFlightStatus}
          loanIds={loanIds}
          preApprovedStatus={preApprovedStatus}
          selectedCaseType={selectedCaseType}
          selectedPhase={selectedPhase}
          setIsSubmitDisabled={setIsSubmitDisabled}
          setSelectedPhase={setSelectedPhase}
        />
        <InFlightToggleSwitch
          inFlightStatus={inFlightStatus}
          loanIds={loanIds}
          preApprovedStatus={preApprovedStatus}
          selectedCaseType={selectedCaseType}
          selectedPhase={selectedPhase}
          setInFlightStatus={setInFlightStatus}
          setIsSubmitDisabled={setIsSubmitDisabled}
          toggleName="InFlight"
        />
        <PreApprovedToggleSwitch
          inFlightStatus={inFlightStatus}
          loanIds={loanIds}
          preApprovedStatus={preApprovedStatus}
          selectedCaseType={selectedCaseType}
          selectedPhase={selectedPhase}
          setIsSubmitDisabled={setIsSubmitDisabled}
          setPreApprovedStatus={setPreApprovedStatus}
          toggleName="Pre Approved"
        />
        <div styleName="loan-numbers">
          <span styleName="typography">
            {'Loan ID(s)'}
          </span>
          <div styleName="status-details">
            <TextField
              id="ids"
              margin="normal"
              multiline
              onChange={handleLoanIdsChange}
              rows={10}
              style={{
                width: '95%',
                resize: 'none',
                overflowY: 'scroll',
                borderRadius: '4px',
                height: '135px',
                padding: '7px',
                scrollbarWidth: 'thin',
                scrollbarColor: '#ccc transparent',
              }}
              value={loanIds}
            />
          </div>
          {excpMessage !== '' && (<p style={{ color: 'red' }}>{excpMessage}</p>)}
        </div>
        <div styleName="interactive-button">
          <div>
            <Button
              className="material-ui-button"
              color="primary"
              disabled={isSubmitDisabled}
              id="submitButton"
              margin="normal"
              onClick={handleGo}
              styleName="submitButton"
              variant="contained"
            >
              GO
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

LeftPane.propTypes = {
  boardingPhaseOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  caseTypeOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  excpMessage: PropTypes.string.isRequired,
  inFlightStatus: PropTypes.bool.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  loanIds: PropTypes.string.isRequired,
  onGo: PropTypes.func.isRequired,
  preApprovedStatus: PropTypes.bool.isRequired,
  selectedCaseType: PropTypes.string.isRequired,
  selectedEvent: PropTypes.shape({
    eventCode: PropTypes.string.isRequired,
    hasMetadata: PropTypes.bool.isRequired,
  }).isRequired,
  selectedPhase: PropTypes.string.isRequired,
  setBoardingPhasesOperation: PropTypes.func.isRequired,
  setInFlightStatus: PropTypes.func.isRequired,
  setIsSubmitDisabled: PropTypes.func.isRequired,
  setLoanIds: PropTypes.func.isRequired,
  setPreApprovedStatus: PropTypes.func.isRequired,
  setSelectedCaseType: PropTypes.func.isRequired,
  setSelectedPhase: PropTypes.func.isRequired,
};

export default LeftPane;
